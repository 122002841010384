body {
  font-family: sans-serif;
}

.pop-up-container {
  z-index: 800;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Semi-transparent background overlay */
  backdrop-filter: blur(10px);
}

.pop-up {
  position: fixed;
  width: 350px;
  height: auto;
  background-color: #e1dbcb;
  opacity: 80%;
  border-radius: 10px; /* Optional: Add rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Optional: Add shadow for depth */
  padding: 20px; /* Optional: Add padding for content */
  z-index: 1000; /* Higher z-index than the overlay */
  text-align: center;
  color: black;
}

.close-icon {
  height: 2rem;
  width: 2rem;
  background-color: transparent;
}
.close-button {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.list-items {
  margin-bottom: 0;
  margin-top: 2rem;
  text-align: left;
  font-size: 18px;
}

.engraving-list {
  margin-top:2px;
  
}

.engraving-item {
  margin: 0;
  margin-top: 10px;
}

.list-items > li {
  margin-bottom: 0; /* Adjust the margin to increase or decrease the gap */
}

.cart-icon {
  width: 2.5rem;
  height: 2.5rem;
  
}

@media screen and (min-width: 480px) {
  .cart-icon {
    margin-left:64px;
  }  
}