.nav-bar {
  display: fixed;
  top: 1rem;
  width: 100%;
  max-width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(255, 255, 255);
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 1.5rem;
  font-family: sans-serif;
  height: 100px;
  text-align: center;
  padding-left: 5rem;
  padding-right: 2rem;
}
@media screen and (min-width: 430px) {
  .nav-bar {
    right: 1rem;
  }
  .cart-icon {
    margin-right: 4rem;
  }
}

.vongo-heading {
  font-family: sans-serif;
  font-weight: 300;
  gap: 2px;
  color: #A5a896;
}

.menu-icon {
  background-color: transparent;
  height: auto;
  width: 100%;
}

.drop-down-button {
  height: 3rem;
  width: 3rem;
  left: 1.5rem;
  top: 1.7rem;
  position: fixed;
  z-index: 1000;
  border-radius: 50%;
  border-style: none;
  transition: all 0.5s;
  background-color: transparent;
}

.drop-down-button:hover {
  cursor: pointer;
}

.cart-icon {
  width: 2.5rem;
  height: 2.5rem;
}

.drop-down-button-pressed {
  height: 3rem;
  width: 3rem;
  left: 2rem;
  top: 2.6rem;
  position: fixed;
  z-index: 100;
  border-radius: 50%;
  border-style: none;
  transition: all 0.5s;
  transform: rotate(90deg);
  background-color: transparent;
}
.drop-down-button-pressed:hover {
  cursor: pointer;
}
