.footer-div {
    height: 700px;
    background-color:  rgb(243, 239, 235);
    font-family: "Helvetica Neue", Arial,sans-serif;
    font-weight: 300;
    padding-left:30px;
    padding-right: 30px;
    border-top: 2px solid rgb(195, 154, 109);
    border-bottom: 2px solid rgb(195, 154, 109);
    padding-top:80px;
    padding-bottom:150px;
   
}

.subscribe-heading {
    color: rgb(195, 154, 109);
    padding-bottom: 20px;
    font-size: 1.6rem;
  
}

.input-1 {
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-size: 16px;
    padding: 0.4rem;
    height: 50px;
    width: 100%;
    margin-top: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    box-sizing: border-box;
    border-style: none;
    border-radius: 8px;
    
  }

  .input-form:focus {
    border: 1px solid black ;
    outline:none;
  }


  .subscribe-button {
    width: 100%;
    height: 50px;
    cursor: pointer;
    background-color: #00334C;
    border: none;
    color: white;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    border-radius: 8px;
  }

  .contact-info-div {
    padding-top: 50px;
    color: rgb(195, 154, 109);
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }

.contact-list {
    padding-top:15px;
}

.contact-list-item {
    padding-bottom: 10px;
}

.social-media-div {
    padding-top: 25px;
    color: rgb(195, 154, 109);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.socials-icon-div {
    padding-top: 40px;
    display:flex;
    justify-content: center;
    gap: 10px;
}

.social-button {
    
    border-style: none;
    background-color: transparent;
}
.social-icon {
    width: 40px;
    background-color: transparent;
    border-style:none;
    
    cursor: pointer;
}


@media screen and (min-width: 480px) {
  .subscribe-form-div {
    display:flex;
    justify-content: center;
}
.input-1 {
    width: 100%;
}
  .subscribe-form-header {
    display:flex;
    justify-content: center;
  }

}
