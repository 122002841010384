@media screen and (min-width: 440px) {
  .cart-grid {
    display: grid;
    grid-template-columns: 1fr;
 
  }
  .cart-info {
    
    margin: 2rem;
  }
}
h4 {
  font-family: "Helvetica Neue", Arial,sans-serif;
}

.cart-heading-h1 {
  font-weight: 450;
}


body {
  overflow-x: hidden;
}

.cart-item-text {
  font-family: "Helvetica Neue", Arial,sans-serif;
  font-weight: 300;
  line-height: 30px
}
h3, h1 {
  font-family: "Helvetica Neue", Arial,sans-serif;
  font-weight: 300;
  line-height: 30px
}

.price-excl-shipping {
  display: inline-block;
  
}



.cart-grid {
  padding: 0.4rem;
  font-family: "Helvetica Neue", Arial,sans-serif;

}

.cart-items-grid {
  display: grid;
  margin: 20px;
  padding: 5px;
  place-items: center;
  border-bottom:  1px solid rgb(255, 138, 61);
}


.items-in-cart {
  display: flex;
  gap: 1rem;
  padding-bottom: 1rem;
  
  margin-left:0 ;
 
}


.cart-engraving-header {
  font-family: "Helvetica Neue", Arial,sans-serif;

}

.engraving-item-cart {
  list-style: none;
  margin-top: 15px;
  color: #848383;
  padding-bottom: 5px;

}

.dropdown-selector-div {
  display: inline-block;
  align-items: center;
  position: relative;
  height: 30px;
  margin-top: 15px;
}

.vongo-flask-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.qty-selector {
  display: inline-block;
  width:60px;
  background-color: rgb(243, 239, 235);
  appearance: none;
  border-style: none;
  cursor: pointer;
  font-size: 20px;
  z-index: 20;
}

.qty-selected::after {
  appearance: none;
  border-style: none;
}

.dropdown-icon {
  width: 20px;

}
.dropdown-icon-span {
  display: inline-block; 
  position: absolute;
  left:30px;
  z-index: 1;
  top: 2px;
  pointer-events: none;
}


.bottle-cart-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
}

.bottle-cart-info > div:last-child {
  margin-left: auto;
}

.item-header-head {
  margin:0;
  font-size: 26px;
  padding-top: 20px;
}

.cart-item-text {
  display: flex;
  text-align: start;
  padding-top: 15px;
}

.item-price-div {
  width:100%;
 
}


.item-price {
  display: block;
  text-align: right;
  
}

.individual-item-price {
  font-size: 26px;
}

.remove-cart-div {
  display: absolute;
  bottom: 2px;
}

.image-in-cart {
  width: 200px;
}
@media  screen and (min-width: 480px) {

  .cart-grid {
    width: 100%;
  }

  .cart-items-grid {
    grid-template-columns: 300px 1fr 1fr;
    
  }
  
  .bottle-cart-info {
    min-width: 400px;
    margin-left: 20px;
    justify-content: space-around;
    padding-top: 40px;
    padding-bottom:40px;
  }

  .bottle-cart-info > div:last-child {
    margin-left: 0;
  }



  .individual-item-price {
    font-size: 32px;
  }

}


@media screen and (min-width: 480px) {
  .image-in-cart {
    width: 180px;
  }
 
}


.cart-heading {
  text-align: center;
  border-bottom: 1px solid rgb(255, 138, 61);
  margin-bottom: 100px;
  margin-top: 50px;
}
.cart-item-text {
  font-family: "Helvetica Neue", Arial,sans-serif;
  line-height: 200%;
}




.total {
  display: grid;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
  padding-bottom: 60px;
}
.shipping-price {
  display:flex;
  justify-content: space-between;
  width: 300px;
  height: 50px;
  align-items: center;
}



.shipping-price-text {
  font-family: "Helvetica Neue", Arial,sans-serif;
  font-weight: 300;
}
.shipping-price-text-t {
  font-weight: 400;
}

.form-header {
  display:flex;
  justify-content: center;
  border-bottom: 1px solid #565D4C;
  margin-bottom: 70px;
}
.form-header-h1 {
  font-weight: 450;
  color: #716A42;
}


@media screen and (min-width: 480px) {
  .total {
    margin-right:20px;
  }
  .shipping-price {
    width: 400px;
  }


}

.total-price {
  max-width: 120px;
}

.trash-icon {
  height: 2rem;
  width: 2rem;
}

.remove-button-div {
  display: flex;
  justify-content: end;
}
.remove-button {
  height: 40px;
  border-style: none;
  background: none;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  padding:0;
  margin-bottom: 20px;
}

.remove-button-text {
  font-weight: 250;
  color: rgb(255, 138, 61);
}


